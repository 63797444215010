import React from 'react';
import { motion } from 'framer-motion';

const FeatureItem = ({ emoji, title, description }) => (
  <motion.div 
    className="bg-gray-800 bg-opacity-50 p-4 sm:p-6 md:p-8 rounded-2xl backdrop-blur-sm border border-pink-500 border-opacity-20 text-center shadow-lg hover:shadow-2xl transition-all duration-300"
    whileHover={{ scale: 1.03, backgroundColor: 'rgba(236, 72, 153, 0.1)' }}
    transition={{ duration: 0.2 }}
  >
    <div className="text-4xl sm:text-5xl mb-3 sm:mb-4 filter drop-shadow-md">{emoji}</div>
    <h3 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-3 text-pink-400">{title}</h3>
    <p className="text-sm sm:text-base text-green-300 leading-relaxed">{description}</p>
  </motion.div>
);

export default function MemepotLandingPage() {
  return (
    <div className="min-h-screen bg-gray-900 text-green-400 py-8 sm:py-12 md:py-16 px-4 font-mono flex flex-col justify-center items-center relative overflow-hidden">
      <div className="absolute inset-0 overflow-hidden opacity-5 pointer-events-none">
        {Array.from({ length: 20 }).map((_, i) => (
          <div key={i} className="whitespace-nowrap text-[8px] sm:text-xs text-pink-500" style={{ transform: `rotate(${i * 9}deg)` }}>
            {Array.from({ length: 50 }).map((_, j) => (
              <span key={j}>loot the memepot </span>
            ))}
          </div>
        ))}
      </div>
      
      <div className="max-w-xs sm:max-w-md md:max-w-2xl lg:max-w-4xl w-full text-center relative z-10">
        <motion.div
          className="mb-8 sm:mb-12"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold mb-4 sm:mb-6 text-pink-500 text-shadow-lg filter drop-shadow-lg">
            🍯 memepot.fun
          </h1>
          <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl text-green-300 max-w-3xl mx-auto leading-relaxed mb-6">
            GM Explorer! MemePot is built for the latecomers, the degens who always arrive late to the pump. This time, it's your chance to shine! ✨
          </p>
          <motion.a
            href="https://t.me/memepotfun"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-blue-500 hover:bg-blue-400 text-white font-bold py-3 sm:py-4 px-8 sm:px-12 rounded-full text-lg sm:text-xl shadow-lg hover:shadow-xl transition-all duration-300 mb-4"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <span className="flex items-center justify-center">
              <svg className="w-5 h-5 sm:w-6 sm:h-6 mr-2" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.944 0A12 12 0 0 0 0 12a12 12 0 0 0 12 12 12 12 0 0 0 12-12A12 12 0 0 0 12 0a12 12 0 0 0-.056 0zm4.962 7.224c.1-.002.321.023.465.14a.506.506 0 0 1 .171.325c.016.093.036.306.02.472-.18 1.898-.962 6.502-1.36 8.627-.168.9-.499 1.201-.82 1.23-.696.065-1.225-.46-1.9-.902-1.056-.693-1.653-1.124-2.678-1.8-1.185-.78-.417-1.21.258-1.91.177-.184 3.247-2.977 3.307-3.23.007-.032.014-.15-.056-.212s-.174-.041-.249-.024c-.106.024-1.793 1.14-5.061 3.345-.48.33-.913.49-1.302.48-.428-.008-1.252-.241-1.865-.44-.752-.245-1.349-.374-1.297-.789.027-.216.325-.437.893-.663 3.498-1.524 5.83-2.529 6.998-3.014 3.332-1.386 4.025-1.627 4.476-1.635z"/>
              </svg>
              Play Our Memegame 🤖
            </span>
          </motion.a>
          <p className="text-sm sm:text-base text-green-300">
            Dive into our memegame while you wait! The winning project gets a $50,000 buyback and whitelist access when we launch! 🔥
          </p>
        </motion.div>

        <motion.div
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 md:gap-8 mb-8 sm:mb-12"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <FeatureItem
            emoji="🐋"
            title="Become a Whale"
            description="Finally, a chance for latecomers to acquire whale status without infinite capital."
          />
          <FeatureItem
            emoji="💧"
            title="Boost Liquidity"
            description="CTOs can attract real liquidity to support their community and push prices."
          />
          <FeatureItem
            emoji="🔄"
            title="Dynamic Gameplay"
            description="More keys = more money = more time = more action! Keep the pot growing."
          />
        </motion.div>

        <motion.div
          className="mb-8 sm:mb-12"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          <p className="text-xl sm:text-2xl md:text-3xl text-green-300 mb-4">
            ...Launching soon on Solana...
          </p>
          <motion.a
            href="https://x.com/memepot_fun"
            target="_blank"
            rel="noopener noreferrer"
            className="text-pink-500 text-lg sm:text-xl font-bold hover:text-pink-400 transition-colors duration-300"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            [TWITTER]
          </motion.a>
        </motion.div>
      </div>
    </div>
  );
}
